import { TeaserType } from '../../domain/TeaserType.ts';

export enum MidnightRange {
	OVER_24_HOURS = 'OVER_24_HOURS',
	BETWEEN_24_HOURS_AND_1_SECOND = 'BETWEEN_24_HOURS_AND_1_SECOND',
	LESS_THAN_1_SECOND = 'LESS_THAN_1_SECOND'
}

const getPackageNameString = (teaserType: TeaserType): string => {
	switch(teaserType) {
		case TeaserType.VIP_EXPIRY_TEASER:
			return 'Upgrade';
		default:
			return 'UP Plus';
	}
}

export const getHeadlineUpdates = (countdown: number, headline: string, teaserType: TeaserType, intervalId: number): string => {
	let parsedHeadline = headline;

	const now = new Date();
	const end = new Date(countdown! * 1000);
	const time_range = end.getTime() - now.getTime();
	const midnightRange = getMidnightRange(time_range);

	switch (midnightRange) {
		case MidnightRange.BETWEEN_24_HOURS_AND_1_SECOND:
			parsedHeadline = headline + ' in ' + getMidnightRangeString(time_range);
			break;
		case MidnightRange.LESS_THAN_1_SECOND:
			window.clearInterval(intervalId);
			parsedHeadline = `Dein ${getPackageNameString(teaserType)} ist abgelaufen`;
			break;
		default:
			break;
	}

	return parsedHeadline
}

const getMidnightRangeString = (time_range: number): string  => {
	const { hours, minutes, seconds } = getTimeFromMilliseconds(time_range);

	const hours_string = hours > 0 ? `${hours}h ` : '';
	const minutes_string = minutes > 0 ? `${minutes}m ` : '';
	const seconds_string = seconds > 0 ? `${seconds}s` : '';

	return `${hours_string}${minutes_string}${seconds_string}`;
}

const getMidnightRange = (time_range: number): MidnightRange => {
	if (time_range < (24 * 60 * 60 * 1000) && time_range > 1000) {
		return MidnightRange.BETWEEN_24_HOURS_AND_1_SECOND;
	} else if (time_range <= 1000) {
		return MidnightRange.LESS_THAN_1_SECOND;
	} else {
		return MidnightRange.OVER_24_HOURS;
	}
}


const getTimeFromMilliseconds = (milliseconds: number) => {
	const getHourFromMilliseconds = (milliseconds: number) => {
		return Math.floor(
			(milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	};

	const getMinutesFromMilliseconds = (milliseconds: number) => {
		return Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
	}

	const getSecondsFromMilliseconds = (milliseconds: number) => {
		return Math.floor((milliseconds % (1000 * 60)) / 1000);
	}

	return {
		hours: getHourFromMilliseconds(milliseconds),
		minutes: getMinutesFromMilliseconds(milliseconds),
		seconds: getSecondsFromMilliseconds(milliseconds)
	}
}
